<template>
  <ContainerCard :locationMsg="locationMsg" :width="85">
    <div class="industryDetail flex-col">
      <div class="topBox flex-row justify-between">
        <div class="sectionLeft flex-col">
          <div class="titleInfo">
            <div class="groupLeft" v-if="detailData.logo">
              <el-image
                class="img1"
                referrerpolicy="no-referrer"
                :src="httpConfig.baseURL+detailData.logo"
              />
            </div>
            <div class="groupRight">
              <span class="title1">{{ detailData.name }}</span>
              <!-- <div class="info flex-row">
                <div class="label flex-col" :key="item + index" v-for="(item, index) in detailData.techList">
                  {{ item }}
                </div>
              </div> -->
              <div class="title2">
                <div class="top">
                  <div class="top-word" >
                    <div class="scale">
                      <span class="label">企业规模</span>
                      <span class="value">{{CompanySize[detailData.company_size]}}</span>
                    </div>
                    
                    <div class="phone">
                      <span class="label">联系电话</span>
                      <span class="value">{{ detailData.mobile }}</span>
                    </div>
                    
                  </div>
                  <span class="top-word">
                    <div class="alliance">
                      <span class="label">加入联盟</span>
                      <span class="value">{{detailData.alliance_name}}</span>
                      <span class="value" v-if="!detailData.alliance_name">暂无</span>
                    </div>
                    <div class="mailbox">
                      <span class="label">邮箱</span>
                      <span class="value">{{ detailData.email }}</span>
                    </div>
                  </span>
                  
                </div>
                <!-- <div class="middle">
                  <span class="word" v-if="detailData.mobile">
                    <span class="label">联系电话</span>
                    <span class="value">{{ detailData.mobile }}</span>
                  </span>
                  <span class="word" v-if="detailData.email">
                    <div class="word-box">
                      <span class="label">邮箱</span>
                      <span class="value">{{ detailData.email }}</span>
                    </div>
                  </span>
                </div> -->
                <div class="bottom">
                  <span class="wordBottom" v-if="detailData.address">
                    <span class="label">单位地址</span>
                    <span class="value">{{ detailData.address }}</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div  class="inline"></div>
          <div  class="info">
            <div class="info-title">
              <img src="../../assets/images/信息资讯.png" alt="" />
              <span class="label">行业划分</span>
            </div>
            <div
              class="info-item"
              :key="item + index"
              v-for="(item, index) in detailData.indusList"
            >
              {{ item }}
            </div>
          </div>
          <div class="inline"></div>
          <div class="info">
            <div class="info-title">
              <img src="../../assets/images/信息资讯.png" alt="" />
              <span class="label">企业简介</span>
            </div>
            <div v-if="detailData.memo" class="info-value" >{{detailData.memo}}</div>
            <div v-else class="info-img">
              <img src="../../assets/images/暂无内容.png" alt="">
              <p class="empty-text">暂无内容</p>
            </div>
          </div>
          <!-- <span
            class="artContainer"
            style="line-height: 20px"
            v-html="detailData.memo"
          ></span> -->
          <div class="inline flex-col"></div>

          <div class="info" style="padding-bottom: 0px">
            <div class="info-title">
              <img src="../../assets/images/信息资讯.png" alt="" />
              <span class="label">合作咨询</span>
            </div>
            <div class="info-consulting">
              <div class="tit">福建省产教融合综合服务平台</div>
              <div class="consulting-item">
                <span class="label">联系地址</span>
                <span class="value"
                  >福建省泉州市小店XX区南中环桥东创新街智创城省级双创中心B座办公室（一）</span
                >
              </div>
              <div class="consulting-item">
                <span class="label">联系电话</span>
                <span class="value">+86 0351-2559898</span>
              </div>
              <div class="consulting-item">
                <span class="label">联系邮箱</span>
                <span class="value">sxscjrhcjh@163.com</span>
              </div>
            </div>
          </div>
        </div>
        <div class="sectionRight">
          <!-- <span class="title">最新入驻</span> -->
          <div class="title">
            <img src="../../assets/images/信息资讯.png" alt="" />
            <span>最新入驻</span>
          </div>
          <div v-for="(item, index) in hotData" :key="index" class="item" @click="toIndustryDetails(item.billid)">
            <div class="left" >
              <el-image
                class="imgThem"
                referrerpolicy="no-referrer"
                v-if="item.logo"
                :src="httpConfig.baseURL+item.logo"
              />
            </div>
            <div class="left-no" v-if="!item.logo">
              <el-image
                class="imgThem"
                referrerpolicy="no-referrer"
                :src="defaultImg " 
              />
            </div>
            <div class="right">
              <div class="text1">
                {{ item.name }}
              </div>
              <div class="text2">
                <span class="label">发布人</span>
                <span class="value">{{ item.contact }}</span>
              </div>
              <div class="text3">
                <span class="label">时间</span>
                <span class="value"> {{ item.make_date }} </span>
              </div>
              <!-- <span class="text1">{{ item.name }}</span>
              <span class="text2">{{ item.contact }}</span>
              <span class="text3">{{ item.make_date }}</span> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </ContainerCard>
</template>
<script>
import ContainerCard from "../../components/newContainerCard.vue";
import {
  getIndustryById,
  getNewestEnterprise,
} from "../../api/industry/industry.js";
import { mapGetters } from "vuex";
import defaultImg from "../../assets/images/暂无数据默认图片.png";
import { getPlainText } from "../../common/utils/tool";
import httpConfig from "../../common/utils/config";
import { res as RES } from './data'
export default {
  computed: {
    ...mapGetters(["techfieldList"]),
  },
  inject: ["reload"],
  data() {
    return {
      httpConfig,
      constants: {},
      hotData: [],
      defaultImg,
      detailData: {
        name: "",
        techList: [],
      },
      locationMsg: [
        { name: "产融型企业", router: "industry" },
        { name: "企业详情", router: "industry/industryDetails" },
      ],
      CompanySize:{
        1:"1-50人",
        2:"50-100人",
        3:"100-200人",
        4:"200及以上",
      },
      
    };
  },
  created() {
    let id = this.$route.query.id;
    this.getData(id);
    this.getHotData();
  },
  methods: {
    reloadFun() {
      this.reload();
    },
    toIndustryDetails(id) {
      console.log(id)
      this.$router.replace({
        path: "/industry/industryDetails",
        query: { id: id },
      });
      this.reloadFun();
    },
    async getData(id) {
      let that = this;
      let res = {};
      if (id) {
        res = await getIndustryById({ id: id });
      } else {
        let dieID = this.$route.query.dieID;
        res.data =  RES.find(item => item.dieID === dieID);
      }
      let tmp = res.data;
      tmp.techList = [];
      Object.keys(that.techfieldList).forEach(function (key) {
        if (
          tmp.tech_field &&
          tmp.tech_field.split(",").indexOf(that.techfieldList[key]) > -1
        ) {
          tmp.techList.push(key);
        }
      });
      const regex = new RegExp("<img", "gi");
      tmp.memo =
        res.data?.memo &&
        res.data?.memo.replace(regex, '<img style="max-width: 100%;"');
      tmp.memo = getPlainText(getPlainText(tmp.memo));
      that.detailData = tmp;

    },
    async getHotData() {
      let res = await getNewestEnterprise({ size: 4 });
      this.hotData = res.data;
      console.log("hotData:",this.hotData)
    },
    toIndustryDetail(id) {
      this.$router.replace({
        path: "/demand/demandDetails",
        query: {
          id: id,
        },
      });
      this.reloadFun();
    },
  },
  components: { ContainerCard },
};
</script>
<style scoped lang="scss">
.industryDetail {
  // padding-bottom: 40px;
  width: 1100px;
}
.topBox {
  width: 100%;
  // padding-top: 20px;
  // padding-left: 30px;
  //   height: 551px;
  .sectionLeft {
    width: 756px;
    box-sizing: border-box;
    flex: 1;
    padding-right: 20px;
    // height: 551px;
    .info {
      width: 756px;
      box-sizing: border-box;
      padding: 24px 30px 24px 0px;
      .info-title {
        margin-bottom: 12px;
        img {
          width: 8px;
          height: 10px;
          margin-right: 12px;
        }
        .label {
          font-size: 16px;
          font-family: Medium;
          color: #101010;
          line-height: 24px;
        }
      }
      .info-item {
        display: inline-block;
        border-radius: 34.5px;
        padding: 10px 16px;
        border: 1px solid #f1f1f1;
        color: #101010;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        background-color: #fafafa;
        font-family: Regular;
        margin-right: 12px;
      }
      .info-value {
        font-weight: 500;
        font-size: 16px;
        line-height: 36px;
        color: #404040;
        font-family: Regular;
        text-align: justify;
      }

      .info-img {
        height: 180px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        img {
          width: 68px;
          height: 72px;
        }
        .empty-text {
          font-size: 12px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #8896A7;
        }
      }

      .info-consulting {
        .tit {
          background-color: #2885ff;
          padding: 6px;
          box-sizing: border-box;
          display: inline-block;
          border-radius: 2px 8px 8px 8px;
          color: #ffffff;
          font-size: 14px;
          line-height: 21px;
          margin-bottom: 20px;
        }
        .consulting-item {
          margin-bottom: 20px;
          .label {
            font-size: 12px;
            line-height: 18px;
            color: #8896a7;
            margin-right: 12px;
          }

          .value {
            font-size: 12px;
            line-height: 18px;
            color: #404040;
          }
        }
      }
    }
    .titleInfo {
      width: 756px;
      // height: 158px;
      padding: 24px 30px 24px 0px;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      .groupLeft {
        margin-right: 23px;
        z-index: 126;
        // width: 110px;
        // height: 110px;
        .img1 {
          z-index: 127;
          width: 110px;
          height: 110px;
          border-radius: 6px;
        }
      }
      .groupRight {
        margin-top: -2px;
        .title1 {
          width: 112px;
          height: 28px;
          color: rgba(16, 16, 16, 1);
          font-size: 24px;
          font-family: Bold;
          white-space: nowrap;
          line-height: 36px;
          text-align: left;
          font-weight: 600;
          // margin-left: 3px;
          // margin-bottom: 6px;  
        }

        .title2 {
          // background: red;
          // margin-top: -20px;
          @mixin title2Font {
            font-size: 12px;
            font-family: Regular;
            line-height: 18px;
            color: #8896a7;
          }
          .top {
            display: flex;
            justify-content: space-between;
            margin-top: 4px;
       
            .top-word:nth-child(1) {
              .scale {
                margin-bottom: 8px;
              }
            }
            .top-word:nth-child(2) {
              margin-left: 80px;
              .alliance {
                margin-bottom: 8px
              }
              }
          
            .top-word {
              .label {
                @include title2Font;
                margin-right: 12px;
                
              }
              .value {
                @include title2Font;
                color: #404040;
                font-weight: 500;
              }
            }
          }
          .middle{
            display: flex;
            flex-direction: row;
            // justify-content: space-between;
            margin-top: 12px;
            .word {
              .label {
                @include title2Font;
                margin-right: 12px;
              }
              .value {
                @include title2Font;
                color: #404040;
                font-weight: 500;
              }
            }

          }
          .bottom {
            .wordBottom {
              .label {
                @include title2Font;
                margin-right: 12px;
              }

              .value {
                @include title2Font;
                color: #404040;
                font-weight: 500;
              }
            }
          }
        }
        .word {
          //   width: 178px;
          height: 16px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(16, 16, 16, 1);
          font-size: 16px;
          font-family: Regular;
          white-space: nowrap;
          line-height: 16px;
          text-align: left;
          // margin-top: 16px;
          .word-box{
            margin-left: 135px;

          }
        }
        .wordBottom {
          //   width: 338px;
          height: 16px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(16, 16, 16, 1);
          font-size: 16px;
          font-family: Regular;
          white-space: nowrap;
          line-height: 16px;
          text-align: left;
          margin-top: 10px;
        }
      }
    }
    .inline {
      //   width: 100%;
      height: 1px;
      width: 756px;
      background-color: rgba(216, 216, 216, 1);
      // margin-top: 24px;
    }
    .title {
      height: 28px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(16, 16, 16, 1);
      font-size: 28px;
      font-family: Regular;
      white-space: nowrap;
      line-height: 28px;
      text-align: left;
      margin-top: 24px;
    }
    // .artContainer {
    //   width: 100%;
    //   //   height: 150px;
    //   margin-top: 24px;
    //   display: block;
    //   overflow-wrap: break-word;
    //   color: rgba(16, 16, 16, 1);
    //   font-size: 16px;
    //   font-family: Regular;
    //   line-height: 30px;
    //   text-align: left;
    //   margin-top: 20px;
    //   //   padding: 20px;
    // }
    .contactTitle {
      height: 28px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(16, 16, 16, 1);
      font-size: 28px;
      font-family: Regular;
      white-space: nowrap;
      line-height: 28px;
      text-align: left;
      margin-top: 42px;
    }
    .contactName {
      height: 30px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(16, 16, 16, 1);
      font-size: 16px;
      font-family: Bold;
      white-space: nowrap;
      line-height: 30px;
      text-align: left;
      margin-top: 20px;
      font-weight: 500;
    }
  }
  .sectionRight {
    position: absolute;
    top: 0px;
    right: 0px;
    height: 622px;
    // border: 1px solid rgba(234, 234, 234, 1);
    width: 300px;
    box-sizing: border-box;
    padding-top: 24px;
    .titleLine {
      width: 100%;
      height: 4px;
      // background-color: rgba(20, 86, 159, 1);
    }
    .title {
      width: 300px;
      height: 38px;
      box-sizing: border-box;
      padding: 11px 30px 11px 0px;
      img {
        width: 8px;
        height: 10px;
        margin-right: 12px;
      }

      span {
        font-size: 16px;
        color: #101010;
        line-height: 24px;
        font-family: Medium;
      }
    }
    .item {
      box-sizing: border-box;
      padding: 20px 0px;
      width: 300px;
      height: 106px;
      // margin: 26px 0 0 20px;
      cursor: pointer;
      border-bottom: 1px solid #f1f1f1;
      display: flex;
      flex-direction: row;
      .left {
        .imgThem {
          z-index: 58;
          height: 66px;
          width: 66px;
          border-radius: 6px;
        }
      }
      .left-no {
        width: 66px;
        height: 66px;
        background: #F1F1F1;
        border-radius: 6px;
        text-align: center;
        line-height: 66px;
        .imgThem {
          z-index: 58;
          height: 16px;
          width: 16px;
        }
      }
      .right {
        box-sizing: border-box;
        padding-left: 12px;
        padding-top: 4px;
        @mixin textFont {
          font-size: 12px;
          font-family: Regular;
          // font-weight: 600;
          line-height: 18px;
          color: #8896a7;
        }
        .text1 {
          font-size: 14px;
          font-family: Regular;
          font-weight: 600;
          color: #101010;
          margin-bottom: 10px;
          display: inline-block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 162px;
        }
        .text2 {
          .label {
            @include textFont;
            margin-right: 12px;
          }
          .value {
            @include textFont;
            color: #404040;
            font-weight: 400
          }
        }
        .text3 {
          .label {
            @include textFont;
            margin-right: 18px;
          }
          .value {
            @include textFont;
            color: #101010;
          }
        }
      }
      .groupLeft {
        z-index: 57;
        position: relative;
        width: 120px;
        height: 90px;
        overflow: hidden;
      }
    }
    // .groupRight {
    //   width: 206px;
    //   height: 90px;
    //   .text1 {
    //     // width: 80px;
    //     height: 16px;
    //     display: block;
    //     overflow-wrap: break-word;
    //     color: rgba(16, 16, 16, 1);
    //     font-size: 16px;
    //     font-family: Regular;
    //     white-space: nowrap;
    //     line-height: 16px;
    //     text-align: left;
    //   }
    //   .text2 {
    //     // width: 205px;
    //     height: 24px;
    //     display: block;
    //     overflow-wrap: break-word;
    //     color: rgba(157, 158, 157, 1);
    //     font-size: 16px;
    //     font-family: Regular;
    //     white-space: nowrap;
    //     line-height: 24px;
    //     text-align: left;
    //     margin: 14px 0 0 1px;
    //   }
    //   .text3 {
    //     // width: 205px;
    //     height: 24px;
    //     display: block;
    //     overflow-wrap: break-word;
    //     color: rgba(157, 158, 157, 1);
    //     font-size: 16px;
    //     font-family: Regular;
    //     white-space: nowrap;
    //     line-height: 24px;
    //     text-align: left;
    //     margin: 12px 0 0 1px;
    //   }
    // }
  }
}
.infoAddress {
  height: 16px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(16, 16, 16, 1);
  font-size: 16px;
  font-family: SourceHanSansCN-Regular;
  white-space: nowrap;
  line-height: 16px;
  text-align: left;
  margin-top: 14px;
}
.infoTel {
  height: 16px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(16, 16, 16, 1);
  font-size: 16px;
  font-family: SourceHanSansCN-Regular;
  white-space: nowrap;
  line-height: 16px;
  text-align: left;
  margin-top: 14px;
}
.infoEmail {
  height: 16px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(16, 16, 16, 1);
  font-size: 16px;
  font-family: SourceHanSansCN-Regular;
  white-space: nowrap;
  line-height: 16px;
  text-align: left;
  margin-top: 14px;
}
::v-deep .locationList{
  position: absolute;
  left: -27px;
}
</style>
